import $ from "jquery";

$(document).ready(function(){
	$('#search-icon').click(function(e){
		$('#search-icon').toggleClass('active');
		if($(this).hasClass('active')){
			$('#search-form').css('display', 'flex');
			// $('#search-icon').hide();
			// $('.search-nav').css('float', 'none');
			$('#close-search').css('display', 'block');
			// $('.search-nav').css('position', 'absolute');
			$('.search-nav-li').toggleClass('active');
			// $('.reg').hide();
		}
		else{
			$('#search-form').hide();
			$('#search-icon').show();
			// $('.search-nav').css('float', 'right');
		}
	});
	$('#close-search').click(function(){
		$('#search-icon').toggleClass('active');
		$('#search-form').hide();
		// $('#search-icon').show();
		// $('.search-nav').css('float', 'right');
		$('#close-search').hide();
		// $('.search-nav').css('position', 'relative');
		$('.search-nav-li').toggleClass('active');
		// $('.reg').show();
	});
	// $(document).click(function(event) { 
	//   var $target = $(event.target);
	//   console.log($target);
	//   if(!$target.closest('#search-icon').length){
	//   	$('#search-icon').removeClass('active');  
	// 	  $('#search-form').hide();
	// 	$('#search-icon').show();
	// 	$('.search-nav').css('float', 'right');   
	//   }
		  
	// });
	$('#search-icon-mobile').click(function(e){
		$('#search-icon-mobile').toggleClass('active');
		if($(this).hasClass('active')){
			$('.search-nav-mobile').toggleClass('active');
			$('#search-form-mobile').css('display', 'flex');
			$('#search-icon-mobile').hide();
			// $('.search-nav').css('float', 'none');
			$('#close-search-mobile').css('display', 'block');
			// $('.search-nav').css('position', 'absolute');
			$('.search-nav-li').toggleClass('active');
			// $('.reg').hide();
		}
	});
	$('#close-search-mobile').click(function(){
		$('#search-icon-mobile').toggleClass('active');
		$('#search-form-mobile').hide();
		$('#search-icon-mobile').show();
		// $('.search-nav').css('float', 'right');
		$('#close-search-mobile').hide();
		// $('.search-nav').css('position', 'relative');
		$('.search-nav-li').toggleClass('active');
		$('.search-nav-mobile').toggleClass('active');
		// $('.reg').show();
	});
});