import $ from "jquery";

$(document).ready(function(){
//recipe collection filtering
	$('.category-filters').on('change', function() {
	    var filter = this.value;
	    console.log(filter);
	    $.ajax({
	        url: '/recipe/category/' + filter,
	        type: 'get',
	        data: {}
	    }).done(function(response){
	        console.log('ajax done');
	        $('.recipe-list').html(response);
	    });

	});
	$('.category-filter').click(function(e){
	    e.preventDefault();
	    $('.category-filter').removeClass('active');
	    $(this).addClass('active');
	    var link = $(this).attr('href');
	    $.ajax({
	        url: '/blog/category/' + link,
	        type: 'get',
	        data: {}
	    }).done(function(response){
	        console.log('ajax done');
	        $('.blog-list').html(response);
	    });
	});

	$('.sort-filters a').click(function(e){
		e.preventDefault();
	    $('.sort-filters a').removeClass('active');
	    $(this).addClass('active');
	    var sort = $(this).data('filter');
	    console.log(sort);
	    $.ajax({
	        url: '/sort-recipes',
	        type: 'post',
	        headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
	        data: {'sort': sort}
	    }).done(function(response){
	        console.log('ajax done');
	        $('.recipe-list').html(response);
	    });
	});
});