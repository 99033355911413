require('./bootstrap');
require('./stripe');
require('./menuNav');
require('./recipes');
require('./blog');
require('./faqList');
require('./dashboard');
require('./kickstarters');
require('./mealPlan');
require('./groceryList');
require('./header');
require('./culinarySchool');

const WOW = require('wowjs');
import "wowjs/css/libs/animate.css"

window.wow = new WOW.WOW({
    live: false
});

window.wow.init({
    offset: 50,
});

//Maybe for future
// window.Vue = require('vue');

// Vue.component('example', require('./components/Example').default);
// Vue.component('blog-posts', require('./components/BlogPosts').default);

// const app = new Vue({
//     el: '#app'
// });


