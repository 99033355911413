import $ from "jquery";

$(document).ready(function(){
	$('#beginLesson').submit(function(e){
		e.preventDefault();
		var d = new Date();

		var month = d.getMonth()+1;
		var day = d.getDate();

		var output = d.getFullYear() + '/' +
		    ((''+month).length<2 ? '0' : '') + month + '/' +
		    ((''+day).length<2 ? '0' : '') + day;
		$.ajax({
	        url: '/start-lesson',
	        type: 'post',
	        headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
	        data: {'currentDate': output}
	    }).done(function(response){
	        console.log('ajax done');
	        window.location.href = '/mykickstart';
	    });
	});
});