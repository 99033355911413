import $ from "jquery";

$(document).ready(function () {
    $(".mkFaq").click(function(e){
        e.preventDefault();
        if($(this).hasClass('active')){
            $(this).removeClass('active');
        }else{
            $(".mkFaq").removeClass('active');
            $(this).addClass('active');
        }
    });
});
