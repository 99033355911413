import $ from "jquery";

$(document).ready(function(){
	var getUrlParameter = function getUrlParameter(sParam) {
	    var sPageURL = window.location.search.substring(1),
	        sURLVariables = sPageURL.split('&'),
	        sParameterName,
	        i;

	    for (i = 0; i < sURLVariables.length; i++) {
	        sParameterName = sURLVariables[i].split('=');

	        if (sParameterName[0] === sParam) {
	            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
	        }
	    }
	};
	// $('.add-to-meal-plan').click(function(e){
	$(document).on('click', '.add-to-meal-plan',function(e){
		console.log('clicked');
		e.preventDefault();
		var id = $(this).data('id');
		var recipeTitle = $(this).data('title');
		var recipeImg = $(this).data('img');

		$('.meal-plan-overlay .recipe-image').attr('src', recipeImg);
		$('.meal-plan-overlay .recipe-name').text(recipeTitle);
		$('.meal-plan-overlay .add-to-meal-plan-actual').attr('data-id', id);
		if(getUrlParameter('day')){
			$('.meal-plan-overlay select').val(getUrlParameter('day'));
		}
		//show popup for day
		// alert($(this).data('id'));
		$('.overlay').fadeIn();
		$('.meal-plan-overlay').fadeIn();
	});

	$('.add-to-meal-plan-actual').click(function(e){
		e.preventDefault();
		console.log($(this).data('id'));
		var id = $(this).data('id');
		var day = $('.meal-plan-overlay select').val();
		$.ajax({
            url: '/add-recipe',
            type: 'post',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {'id': id, 'day': day}
        }).done(function(response){
            console.log('ajax done');
            sessionStorage.removeItem('grocerylist');
            window.location.href = '/my-meal-plan';
        });

	});

	$('#close-meal-plan-overlay').click(function(e){
		e.preventDefault();
		$('.overlay').fadeOut();
		$('.meal-plan-overlay').fadeOut();
	});

	$('.remove-recipe').click(function(e){
		e.preventDefault();
		var id = $(this).attr('id');
		var day = $(this).parent().parent().parent().attr('id');
		$.ajax({
            url: '/remove-recipe',
            type: 'post',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {'id': id, 'day': day}
        }).done(function(response){
            console.log('ajax done');
            sessionStorage.removeItem('grocerylist');
            window.location.href = '/my-meal-plan';
        });
	});

	$('.favorite-recipe').click(function(e){
		e.preventDefault();
		var id = $(this).attr('id');
		console.log(id);
		$.ajax({
            url: '/favorite-recipe',
            type: 'post',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {'id': id}
        }).done(function(response){
            console.log('ajax done');
            $('.favorite-recipe #favorite-empty').hide();
            $('.favorite-recipe #favorite-full').show();
            // window.location.href = '/my-meal-plan';
        });
	});

	$('.remove-favorite-recipe').click(function(e){
		e.preventDefault();
		var id = $(this).attr('id');
		console.log(id);
		$.ajax({
            url: '/remove-favorite-recipe',
            type: 'post',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {'id': id}
        }).done(function(response){
            console.log('ajax done');
            $('.remove-favorite-recipe #favorite-empty').show();
            $('.remove-favorite-recipe #favorite-full').hide();
            // window.location.href = '/my-meal-plan';
        });
	});

	$('.rating-section span').click(function(e){
		e.preventDefault();
		var id = $(this).data('recipeid');
		var rating = $(this).attr('id');
		console.log(id);
		console.log(rating);
		$.ajax({
            url: '/rate-favorite-recipe',
            type: 'post',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {'id': id, 'rating': rating}
        }).done(function(response){
            console.log('ajax done');
            location.reload();
            // window.location.href = '/my-meal-plan';
        });
	});

	$('#clear-all').click(function(e){
		e.preventDefault();
		$.ajax({
            url: '/clear-meal-plan',
            type: 'post',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {}
        }).done(function(response){
            console.log('ajax done');
            location.reload();
            // window.location.href = '/my-meal-plan';
        });
	});

	// $('.add-day').click(function(e){
	// 	e.preventDefault();
	// 	var ids = $('.day').map(function(index) {
	// 	    // this callback function will be called once for each matching element
	// 	    return this.id; 
	// 	});
	// 	console.log(ids);
		
	// });

});