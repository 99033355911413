import $ from "jquery";

$(document).ready(function () {
    $(".mobileNavicon").click(function(e){
        e.preventDefault();
        var $this = $( this );
        var wasActive = $( 'header .mobileNavicon' ).hasClass( 'active' );
        if( wasActive ){
            $( 'header .mobileNavicon,header .bottom-nav' ).removeClass( 'active' );
        } else {
            $( 'header .mobileNavicon,header .bottom-nav' ).addClass( 'active' );
        }
    });
});
