import $ from "jquery";

$(document).ready(function(){
    //blog collection filtering
    $('.category-filter').click(function(e){
        e.preventDefault();
        $('.category-filter').removeClass('active');
        $(this).addClass('active');
        var link = $(this).attr('href');
        $.ajax({
            url: '/blog/category/' + link,
            type: 'get',
            data: {}
        }).done(function(response){
            console.log('ajax done');
            $('.blog-list').html(response);
        });
    });

    $('#searchBlog').submit(function(e){
        e.preventDefault();
        var keyword = $('#searchField').val();
        $.ajax({
            url: '/blog/search/' + keyword,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: 'post',
            data: {}
        }).done(function(response){
            console.log('ajax done');
            $('.blog-list').html(response);
        });
    });
});
