import $ from "jquery";

$(document).ready(function(){
//recipe collection filtering
	$('.culinary-category-filters').on('change', function() {
	    var filter = this.value;
	    console.log(filter);
	    $.ajax({
	        url: '/culinary-school/category/' + filter,
	        type: 'get',
	        data: {}
	    }).done(function(response){
	        console.log('ajax done');
	        $('.culinary-list').html(response);
	    });

	});
	
});