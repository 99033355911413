import $ from "jquery";

$(document).ready(function(){

	//clikc + hover on tooltip
	$(document).on('click', '.custom-tooltip', function(){
		// console.log($(this).find('.tooltiptext').css('visibility'));
		// $('.tooltiptext').css('visibility', 'visible');
		// $('.tooltiptext').css('visibility', 'hidden');
		if ( $(this).find('.tooltiptext').css('visibility') === 'visible'){
			console.log('already visible');
			$('.tooltiptext').css('visibility', 'hidden');
			$(this).find('.tooltiptext').css('visibility', 'hidden');
		}
		else{
			console.log('hidden');
			$('.tooltiptext').css('visibility', 'hidden');
			$(this).find('.tooltiptext').css('visibility', 'visible');
		}
		
	});

	$('.add-to-meal-plan-actual').click(function(e){
		e.preventDefault();
		console.log($(this).data('id'));
		var id = $(this).data('id');
		var day = $('.meal-plan-overlay select').val();
		$.ajax({
            url: '/add-recipe',
            type: 'post',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {'id': id, 'day': day}
        }).done(function(response){
            console.log('ajax done');
            window.location.href = '/my-meal-plan';
        });

	});
	if(sessionStorage.getItem('grocerylist')){
		console.log('session exists');
		var currentGroceryList = sessionStorage.getItem('grocerylist');
		var currentGroceryList = currentGroceryList.split(',');
		console.log(currentGroceryList);
		// for(var i=0;i<currentGroceryList.length;i++){
		// 	$('#' + currentGroceryList[i]).find('.open-circle').hide();
		// 	$('#' + currentGroceryList[i]).find('.check').show();
		// 	$('#' + currentGroceryList[i]).find('h5').css('text-decoration', 'line-through');
		// }
		// $.each(currentGroceryList, function(c){
		// 	// console.log(c.value);
		// 	$('#' + c).find('.open-circle').hide();
		// 	$('#' + c).find('.check').show();
		// 	$('#' + c).find('h5').css('text-decoration', 'line-through');
		// });
	}
	

	var checkedIngredients = [];
	$(document).on('click', '.list-item h5', function(e){
	// $('.list-item').click(function(e){
		e.preventDefault();
		console.log($(this).parent().attr('id'));
		var currentItem = $(this).parent().attr('id');
		var allItems = $('.list-item').map(function(index){
			return this.id;
		});
		
		console.log(allItems);
		if(sessionStorage.getItem('grocerylist')){
			var currentGroceryList = sessionStorage.getItem('grocerylist');
			var currentGroceryList = currentGroceryList.split(',');
			var mergedArr = $.merge(currentGroceryList, checkedIngredients);
			// sessionStorage.setItem('grocerylist', mergedArr);
		}
		else{
			var mergedArr = null;
		}

		if($(this).parent().find('.open-circle').css('display') == 'inline'){
			checkedIngredients.push(currentItem);
			$(this).parent().find('.open-circle').hide();
			$(this).parent().find('.check').show();
			$(this).parent().find('h5').css('text-decoration', 'line-through');
		}
		else{
			checkedIngredients.splice($.inArray(currentItem, checkedIngredients), 1 );
			$(this).parent().find('.open-circle').show();
			$(this).parent().find('.check').hide();
			$(this).parent().find('h5').css('text-decoration', 'none');
			if(mergedArr){
				currentGroceryList.splice($.inArray(currentItem, currentGroceryList), 1);
			}
			//remove from session storage
		}
		console.log(checkedIngredients);
		if(sessionStorage.getItem('grocerylist')){
			// var currentGroceryList = sessionStorage.getItem('grocerylist');
			// var currentGroceryList = currentGroceryList.split(',');
			// var mergedArr = $.merge(currentGroceryList, checkedIngredients);
			sessionStorage.setItem('grocerylist', mergedArr);
		}
		else{
			sessionStorage.setItem('grocerylist', checkedIngredients);
		}
		
		// $.ajax({
  //           url: '/edit-grocery-list',
  //           type: 'post',
  //           headers: {
  //               'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  //           },
  //           data: {'id': id, 'day': day}
  //       }).done(function(response){
  //           console.log('ajax done');
  //           window.location.href = '/my-meal-plan';
  //       });

	});

	function find_duplicate_in_array(arra1) {
        var object = {};
        var result = [];

        arra1.forEach(function (item) {
          if(!object[item])
              object[item] = 0;
            object[item] += 1;
        })
        console.log(object);
        for (var prop in object) {
           if(object[prop] >= 2) {

               // result.push(prop.split(',')[0]);
               result.push(prop);
           }
        }

        return result;

    }
    // alert(find_duplicate_in_array([1,2,3,4,5,1,2]));

	var aisles = $('.aisle-row');
	var unique_ingredients = {};
	
	// $.each(aisles, function(item){
	// 	var list_of_ingredients = [];
	// 	// console.log($(this).attr('id'));
	// 	var listItems = $(this).find('.list-item h5');
	// 	// console.log(listItems.text());
	// 	$.each(listItems, function(ing){
	// 		list_of_ingredients.push($(this).find('.ingredient').html());
	// 		// console.log($(this).text());
	// 		// console.log($(this).find('.ingredient').text());
	// 		// alert(find_duplicate_in_array[$(this).find('.ingredient').text()]);
	// 		// if(find_duplicate_in_array([1,2,3,4,5,1,2]))
	// 	});
	// 	// console.log(list_of_ingredients);
	// 	var list_of_ingredients_duplicates = [];
	// 	for(var i=0;i<list_of_ingredients.length;i++){
	// 		// if (list_of_ingredients[i + 1] == list_of_ingredients[i]) {
	// 		if($.inArray(list_of_ingredients[i], list_of_ingredients)){
	// 	        list_of_ingredients_duplicates.push(list_of_ingredients[i]);
	// 	        // alert(i);
	// 	        // console.log(i);
	// 	        // console.log(listItems[i]);
	// 	        // $(listItems[i]).css('background', 'black');

	// 	    }
	// 	}
	// 	console.log(list_of_ingredients_duplicates);
	// });

	var listItems2 = $('.list-item h5');
	var list_of_ingredients2 = [];
	var list_of_aisles = [];
	var list_of_measurements = [];
	$.each(listItems2, function(ing){
		if($(this).find('.measurement').text().substr(-1) == 's'){
			var msmt = $(this).find('.measurement').text().slice(0,-1);

		}
		else{
			var msmt = $(this).find('.measurement').text();
		}
		list_of_ingredients2.push([$(this).find('.ingredient-item').text(), msmt]);
		list_of_aisles.push($(this).parent());
		list_of_measurements.push($(this).find('.measurement').text());
	});
	console.log(list_of_ingredients2);
	console.log(list_of_measurements);
	var list_of_ingredients_duplicates2 = [];
	// for(var j=0;j<list_of_ingredients2.length;j++){
	// 	if($.inArray(list_of_ingredients2[j], list_of_ingredients2)){
	// 			console.log(list_of_ingredients2[j]);
	// 	        list_of_ingredients_duplicates2.push(list_of_ingredients2[j]);

	//     }
	// }
	// console.log(list_of_ingredients_duplicates2);
	console.log('list of aisles');
	console.log(list_of_aisles);
	var dupes = find_duplicate_in_array(list_of_ingredients2);
	var dupesNoComma = [];
	for(i=0;i<dupes.length;i++){
		dupesNoComma.push(dupes[i].split(',')[0]);
	}
	console.log(dupes);
	console.log(dupesNoComma);
	var dupesFullQty = [];
	for(var i = 0;i<dupes.length;i++){
		dupesFullQty[i] = 0.00;
	}
	var dupesMeasurements = [];
	var totalQty = 0.00;
	var listIDs = [];
	var dupesRecipes = [];
	var list_of_ingredients3 = []
	$.each(list_of_ingredients2, function(ing){
		list_of_ingredients3.push(list_of_ingredients2[ing].toString());
	});
	console.log('here are a list of ingredients');
	console.log(list_of_ingredients3);
	// var dupesFullIngredients = [];
	// console.log(dupes);
	for(var j=0;j<list_of_ingredients3.length;j++){
		if($.inArray(list_of_ingredients3[j], dupes) !== -1){
				// console.log(list_of_ingredients3[j]);
				// $(listItems2[j]).css('background', 'pink');
				$(listItems2[j]).parent().addClass('duplicate');

				$(listItems2[j]).parent().css('visibility', 'hidden');
				$(listItems2[j]).parent().css('height', '0');
				$(listItems2[j]).parent().css('padding', '0');
				$(listItems2[j]).parent().hide();
				$(listItems2[j]).parent().prev().hide();
				$(listItems2[j]).parent().next().hide();
				listIDs.push($(listItems2[j]).parent().parent().attr('id'));
				for(var k=0; k<dupes.length; k++){
					if(list_of_ingredients3[j] == dupes[k]){
						// alert('dupe found');
						// dupesFullQty.push($(listItems2[j]).find('.measurement-qty').text());
						console.log($(listItems2[j]).find('.measurement-qty').text());
						dupesFullQty[k] = parseFloat($(listItems2[j]).find('.measurement-qty').text()) + parseFloat(dupesFullQty[k]);
						if($(listItems2[j]).find('.measurement').text().substr(-1) == 's'){
							var msmt = $(listItems2[j]).find('.measurement').text().slice(0,-1);

						}
						else{
							var msmt = $(listItems2[j]).find('.measurement').text();
						}
						dupesMeasurements[k] = msmt;
						var tooltip = $(listItems2[j]).parent().find('.tooltiptext').text();

						dupesRecipes[k] = tooltip.slice(7) + ', ' + dupesRecipes[k]; 
					}
				}
				// totalQty = parseFloat($(listItems2[j]).find('.measurement-qty').text());

	    }
	}
	var duplicateListItems = $('.duplicate').parent();
	console.log('duplicateListItems');
	console.log(duplicateListItems);
	for(var m = 0; m<duplicateListItems.length; m++){

	}
	var finalAisles = [];
	for(var i = 0; i<dupes.length; i++){
		var aisle = $('.list-item h5 .ingredient-item:contains("'+ dupesNoComma[i] +'")').parent().parent().parent().parent().attr('id');
		finalAisles.push(aisle);
	}
	console.log(finalAisles);
	// console.log(totalQty);
	console.log(dupes);
	console.log(dupesFullQty);
	// console.log($.unique(listIDs));
	console.log(dupesMeasurements);
	console.log('here are the dupe recipes');
	console.log(dupesRecipes);
	var origListIDs =  listIDs;
	console.log('origListIDs');
	console.log(origListIDs);
	var uniqueList = $.unique(listIDs);
	var listLength = $('.list-item').length;
	console.log('uniqueList');
	console.log(uniqueList);
	for(var l=0;l<finalAisles.length;l++){
		if(dupesFullQty[l] > 1){
			var dupesQty = dupesFullQty[l];
			if(dupesMeasurements[l].slice(-1) == 's'){
				var newMeasurement = dupesMeasurements[l];
			}
			else{
				if(dupesMeasurements[l] != ''){
					var newMeasurement = dupesMeasurements[l] + 's';
				}
				else{
					var newMeasurement = dupesMeasurements[l];
				}
				
			}
		}
		else{
			var dupesQty = dupesFullQty[l];
			var newMeasurement = dupesMeasurements[l];
		}
		for(var j=0;j<origListIDs.length;j++){
			if(origListIDs[j] == uniqueList[l]){

			}
		}
		$('#' + finalAisles[l]).append(
			'<div class="col-md-2"></div>' +
			'<div class="col-md-8 list-item wow fadeInUp new-list-item" id="'+ (listLength + 1) +'"><h5><img class="open-circle" src="/images/icons/open-circle.png"><img class="check" src="/images/icons/check.png"><span class="measurement-qty">' + dupesQty + '</span> <span class="ingredient"><span class="measurement">' + newMeasurement + '</span> <span class="ingredient-item">' + dupesNoComma[l] + '</span></span></h5><div class="custom-tooltip"><img class="info" src="/images/icons/info.png"><span class="tooltiptext">Recipes: '+ dupesRecipes[l] +'</span></div></div>' + 
			'<div class="col-md-2"></div>'
		);
		listLength += 1;


	};
	for(var j = 0; j<$('.new-list-item').length;j++){
		var oldText = $('.new-list-item').eq(j).find('.tooltiptext').text();
	// alert(oldText);
		var newText = oldText.replace(', undefined', '');
		$('.new-list-item').eq(j).find('.tooltiptext').text(newText);
	}

	function gcd(a, b) {
	return (b) ? gcd(b, a % b) : a;
	}
	var decimalToFraction = function (_decimal) {
	    if (_decimal == parseInt(_decimal)) {
	        return {
	            top: parseInt(_decimal),
	            bottom: 1,
	            display: parseInt(_decimal) + '/' + 1
	        };
	    }
	    else {
	        var top = _decimal.toString().includes(".") ? _decimal.toString().replace(/\d+[.]/, '') : 0;
	        var bottom = Math.pow(10, top.toString().replace('-','').length);
	        if (_decimal >= 1) {
	            top = +top + (Math.floor(_decimal) * bottom);
	        }
	        else if (_decimal <= -1) {
	            top = +top + (Math.ceil(_decimal) * bottom);
	        }

	        var x = Math.abs(gcd(top, bottom));
	        return {
	            top: (top / x),
	            bottom: (bottom / x),
	            display: (top / x) + '/' + (bottom / x)
	        };
	    }
	};

	for(var k = 0; k<$('.measurement-qty').length; k++){
		if($('.measurement-qty').eq(k).text().indexOf(".") !== -1){
			if(parseFloat($('.measurement-qty').eq(k).text()) > 1){
				var whole = Math.floor(parseFloat($('.measurement-qty').eq(k).text()));
				// alert(whole);
				var fraction = parseFloat($('.measurement-qty').eq(k).text()) - whole;
				// alert(fraction);
				// alert($('.measurement-qty').eq(k).parent().parent().attr('id'));
			}
			else{
				var whole = '';
				var fraction = parseFloat($('.measurement-qty').eq(k).text());
			}
			
			// var len = fraction.toString().length - 2;

			// var denominator = Math.pow(10, len);
			// var numerator = fraction * denominator;

			// var divisor = gcd(numerator, denominator);  

			// numerator /= divisor;                         // Should be 687
			// denominator /= divisor;    
			var frac = decimalToFraction(fraction);
			var finalFraction = '';
			console.log(frac['display']);
			if(frac['display'] == '33/100'){
				finalFraction = '1/3';
			}
			else if(frac['display'] == '4125000000000001/12500000000000000'){
				finalFraction = '1/3';
			}
			else if(frac['display'] == '66/100'){
				finalFraction = '2/3';
			}
			else if(frac['display'] == '6200000000000001/10000000000000000'){
				finalFraction = '2/3';
			}
			else if(frac['display'] == '33/50'){
				finalFraction = '2/3';
			}
			else if(frac['display'] == '6600000000000001/10000000000000000'){
				finalFraction = '2/3';
			}
			else if(frac['display'] == '1600000000000001/10000000000000000'){
				finalFraction = '1/6';
			}
			else if(frac['display'] == '8300000000000001/10000000000000000'){
				finalFraction = '5/6';
			}
			else if(frac['display'] == '833333333333333/10000000000000000'){
				finalFraction = '1/12';
			}
			else if(frac['display'] == '8000000000000007/100000000000000000'){
				finalFraction = '1/12';
			}
			else if(frac['display'] == '833333333333333/2000000000000000'){
				finalFraction = '5/12';
			}
			else if(frac['display'] == '5800000000000001/10000000000000000'){
				finalFraction = '7/12';
			}
			else if(frac['display'] == '91/100'){
				finalFraction = '11/12';
			}
			else if(frac['display'] == '4583333333333333/5000000000000000'){
				finalFraction = '11/12';
			}
			else if(frac['display'] == '2562500000000001/6250000000000000'){
				finalFraction = '5/12';
			}
			else if(frac['display'] == '8000000000000007/10000000000000000'){
				finalFraction = '4/5';
			}
			else if(frac['display'] == '8000000000000007/50000000000000000'){
				finalFraction = '1/6';
			}
			else{
				if(frac['display'].length >= 6){
					console.log(frac['display']);
					var split = frac['display'].split("/");
					// alert(split[0] + '/'  + split[1]);
					finalFraction = split[0].substring(0,2) + '/'  + split[1].substring(0,3);
					// finalFraction= frac['display'];
				}
				else{
					finalFraction= frac['display'];
				}
				
			}

			if(whole != ''){
				$('.measurement-qty').eq(k).text(whole + ' ' + finalFraction);
			}
			else{
				$('.measurement-qty').eq(k).text(finalFraction);
			}
			
		}
		

	}
	

});