import $ from "jquery";

$(document).ready(function () {
    if ($("#payment-form").length){

        const style = {
            base: {
                // Add your base input styles here. For example:
                fontSize: '20px',
                color: '#7d4197',
                fontWeight: '400',
                fontFamily: 'Josefin Sans',
                '::placeholder': {
                    color: '#7d4197',
                },
            },
        };

        const stripe = Stripe(window.stripeKey);
        const elements = stripe.elements({
            fonts: [
                {
                    // integrate your font into stripe
                    cssSrc: 'https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600&family=Martel:wght@700;800;900&display=swap',
                }
            ]
        });
        const cardElement = elements.create('card', {style: style});
        cardElement.mount('#card-element');
        var price = parseFloat($('#complete-order').data('price'));
        function gtag_report_conversion(url) {
          var callback = function () {
            if (typeof(url) != 'undefined') {
              window.location = url;
            }
          };
          gtag('event', 'conversion', {
              'send_to': 'AW-603501644/69t6CJK62PkBEMzo4p8C',
              'value': price,
              'currency': 'USD',
              'event_callback': callback
          });
            return false;
        }
        //########################################
        let $form = $("#payment-form");
        let $button = $("#complete-order");
        $form.submit(e => e.preventDefault())
        $button.click(async (e) => {
            //Can show load effect
            if ($button.attr('disabled') || !$form[0].checkValidity()){
                return false;
            }
            $button.attr("disabled", true);
            const cardHolderName = $("#fName").val() + " " + $("#fName").val();
            const { paymentMethod, error } = await stripe.createPaymentMethod(
                'card', cardElement, {
                    billing_details: { name: cardHolderName }
                }
            );

            if (error) {
                // Display "error.message" to the user...
                $button.removeAttr("disabled");
                $("#card-errors").removeClass("hidden").html(error.message)
            } else {
                $("#payment_method").val(paymentMethod.id);
                //$("#payment-form").submit();
                $.ajax({
                    url: $form.attr("action"),
                    type: 'POST',
                    data: new FormData($form[0]), // The form with the file    inputs.
                    processData: false,                          // Using FormData, no need to process data.
                    contentType:false
                }).done((res) => {
                    if (res.success){
                        return gtag_report_conversion('https://smartyplants.org/');
                        // window.location.href = res.redirect ? res.redirect : "/"
                    }
                    else{
                        $("#card-errors").removeClass("hidden").html(res.error);
                    }
                }).fail((res) => {
                    $button.removeAttr("disabled");
                    $("#card-errors").removeClass("hidden").html(res.responseJSON.message);
                });
            }
        });
    }
})
