import $ from "jquery";

$(document).ready(function(){
	$('.not-viewed.available').click(function(e){
		e.preventDefault();
		var id = $(this).attr('id');
		var link =  $(this).attr('href');
		$.ajax({
	        url: '/view-lesson/' + id,
	        type: 'get',
	        data: {}
	    }).done(function(response){
	        console.log('ajax done');
	        window.location.href = link;
	    });
	});
});